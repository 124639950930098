import * as React from 'react';
import { MainLink, MainText } from './Text';

const Bio = () => {
  return (
    <>
      <br />
      <br />
      <MainText>
        Hello, I&apos;m{' '}
        {
          <MainLink href={'/images/me.jpg'} target={'none'}>
            Harry Stephens
          </MainLink>
        }
        , a software engineer and product designer. If you&apos;re curious, here is{' '}
        {
          <MainLink href={'resources/Harry_Stephens_Resume.pdf'} target={'none'}>
            my resume
          </MainLink>
        }
        .
        <br />
        <br />
        Currently, I&apos;m building technology for civic action at{' '}
        {
          <MainLink href={'https://www.helmteam.us/'} target={'none'}>
            Helm
          </MainLink>
        }
        . Previously, I worked for{' '}
        {
          <MainLink href={'https://tuesdaycompany.com'} target={'none'}>
            The Tuesday Company
          </MainLink>
        }{' '}
        on a product named{' '}
        {
          <MainLink
            href={'https://apps.apple.com/us/app/team-connect-with-voters/id1270902720'}
            target={'none'}>
            Team
          </MainLink>
        }
        .
        <br />
        <br />
        If you&apos;d like to talk, feel free to{' '}
        {
          <MainLink href={'tel:1-864-542-3206'} target={'none'}>
            call
          </MainLink>
        }{' '}
        me or email me at{' '}
        {
          <MainLink href={'mailto:harrydstephens@gmail.com'} target={'none'}>
            harrydstephens@gmail.com
          </MainLink>
        }
        .
      </MainText>
    </>
  );
};

export default Bio;
