import * as React from 'react';

import '../App.css';
import Bio from './Bio';

const Home = () => {
  return (
    <section>
      <Bio />
    </section>
  );
};

export default Home;
