import styled from 'styled-components';
import Colors from '../styles/colors';
import Fonts from '../styles/fonts';
// import { width } from '../utils/LayoutUtils';

export const Header = styled.p`
  ${
    [
      // Fonts.headerText,
      // {
      //   color: Colors.Slate,
      //   fontSize: 22
      // }
    ]
  }
`;

export const ClickableHeader = styled.a`
  ${
    [
      // Fonts.headerText,
      // {
      //   color: Colors.Pink,
      //   fontSize: 22
      // }
    ]
  },
`;

export const SubHeader = styled.h2`
  ${
    [
      // Fonts.subheaderText,
      // {
      //   color: Colors.Slate,
      //   fontSize: width >= 450 ? 20 : 18
      // }
    ]
  },
`;

export const BodyText = styled.p`
  ${[
    Fonts.bodyText,
    {
      color: Colors.Charcoal
      // fontWeight: 400
    }
  ]},
`;

export const CalloutText = styled.p`
  ${
    [
      // Fonts.calloutText
      // {
      //   color: Colors.Slate
      // }
    ]
  },
`;

export const MainText = styled.p`
  ${[
    Fonts.splashText,
    {
      color: Colors.Charcoal
      // marginTop: 4,
      // marginBottom: 0
      // fontSize: 18
      // fontWeight: 200
    }
  ]},
`;

export const MainLink = styled.a`
  ${
    [
      // Fonts.bodyText,
      // Fonts.linkText
      // {
      //   color: Colors.Pink,
      //   marginTop: 0,
      //   marginBottom: 0,
      //   fontSize: 18,
      //   fontWeight: 400
      // }
    ]
  },
`;

export const CaptionText = styled.p`
  ${
    [
      // Fonts.captionText,
      // {
      //   color: Colors.Charcoal,
      //   fontSize: width >= 450 ? 12 : 13
      // }
    ]
  },
`;

export const BodyLink = styled.a`
  ${
    [
      // Fonts.bodyText,
      // Fonts.linkText,
      // {
      //   color: Colors.Pink
      // }
    ]
  },
`;
