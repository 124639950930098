import * as React from 'react';
import { useEffect, useState } from 'react';

import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
// import Title from './components/Title';
import { BodyText } from './components/Text';
import Portrait from './components/Portrait';

export default function App() {
  const [width, setWidth] = useState(window.innerWidth > 600 ? 600 : '100%');

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth > 600 ? 600 : 'auto');
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <BrowserRouter>
      <section style={styles.body}>
        <section
          style={{
            display: 'flex',
            height: '100%',
            gridArea: 'left-content',
            width: width
          }}>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </section>
        <BodyText style={styles.footerContent}>© Harry Stephens 2022</BodyText>
        {/* <Portrait /> */}
      </section>
    </BrowserRouter>
  );
}

const styles = {
  body: {
    display: 'grid',
    gridTemplate: `
    [row1-start] "left-margin left-content right-content right-margin" 90vh [row1-end]
    [row2-start] "left-margin left-footer right-footer right-margin" auto [row2-end]
    / 5vw 90vw 5vw`
  },
  mainContent: {
    display: 'flex',
    height: '100%',
    gridArea: 'left-content'
  },
  footerContent: {
    display: 'flex',
    gridArea: 'left-footer'
  },
  leftMargin: {
    width: '5vw',
    gridArea: 'left-margin',
    justifySelf: 'end'
  },
  rightMargin: {
    width: 175,
    gridArea: 'right-margin',
    justifySelf: 'start'
  }
};
