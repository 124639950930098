import Colors from './colors';

const Fonts = {
  subtitleText: {
    // display: 'block',
    // fontFamily: 'Open Sans',
    // fontWeight: '300',
    // fontSize: 32,
    // marginBlockStart: 0,
    // marginBlockEnd: 0,
  },
  headerText: {
    // display: 'block',
    // fontFamily: 'EB Garamond',
    // fontWeight: '900',
    // letterSpacing: '-0.01em',
    // marginBlockStart: 0,
    // marginBlockEnd: 0,
  },
  subheaderText: {
    // display: 'block',
    // lineHeight: 1.5,
    // fontFamily: 'EB Garamond',
    // fontStyle: 'italic',
    // fontWeight: '200',
    // letterSpacing: '-0.03em',
    // marginBlockStart: 0,
    // marginBlockEnd: 0,
  },
  bodyText: {
    fontFamily: 'EB Garamond',
    // fontFamily: 'Open Sans',
    // lineHeight: 1.7,
    // fontWeight: 300,
    fontSize: 15,
    // marginBlockStart: 0,
    // whiteSpace: 'pre-line',
  },
  splashText: {
    fontFamily: 'EB Garamond',
    fontWeight: 500,
    fontSize: 24,
  },
  calloutText: {
    // borderLeft: `2px solid ${Colors.Pink}`,
    // paddingLeft: '16px',
    // fontStyle: 'oblique',
    // fontFamily: 'Open Sans',
    // fontWeight: '200',
    // fontSize: 18,
    // whiteSpace: 'pre-line',
    // marginBlockEnd: 0,
    // paddingBottom: 4,
  },
  navigationText: {
    // fontFamily: 'Open Sans',
    // fontWeight: 300,
    // fontSize: 15,
    // marginTop: 12,
    // marginBottom: 12,
    // textAlign: 'right',
    // whiteSpace: 'pre-line',
  },
  navigationTextActive: {
    // fontFamily: 'Open Sans',
    // fontWeight: 600,
    // fontSize: 15,
    // marginTop: 12,
    // marginBottom: 12,
    // textAlign: 'right',
    // whiteSpace: 'pre-line',
    // textDecorationLine: 'underline',
  },
  captionText: {
    // fontFamily: 'Open Sans',
    // fontWeight: 300,
    // marginTop: 6,
    // marginBottom: 6,
    // paddingLeft: '6px',
    // paddingRight: '6px',
  },
  linkText: {
    // textDecorationLine: 'none',
    // borderBottom: 'none',
  },
};

export default Fonts;
