const Colors = {
Pink: '#DD004D',
Blue: '#0000E0',
Rhino: '#ACACAC',
Slate: '#5C585A',
Charcoal: '#222222',
Black: '#000000',
White: '#FFFFFF'
};

export default Colors;
